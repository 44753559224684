<!-- eslint-disable vue/no-v-html -->
<template>
	<w-layout fill-height column>
		<w-flex>
			<v-toolbar dense>
				<w-icon>reset_tv</w-icon>
				<w-flex ml-3>{{ $tc('mobileapp.list.pending_upgrade_counter', nbUpgradePending, { nb_upgrades: nbUpgradePending }) }}</w-flex>
				<w-spacer />
				<w-search-input v-model="search" />
				<w-divider v-if="$vuetify.breakpoint.mdAndUp" class="ml-3" vertical />
				<w-flex shrink>
					<w-btn icon="add" @click="createMobileApp">
						{{ $t('actions.create') }}
					</w-btn>
				</w-flex>
				<w-flex shrink>
					<w-btn icon="reset_tv" @click="upgradeMobileApps">
						{{ $t('mobileapp.list.upgrade') }}
					</w-btn>
				</w-flex>
				<w-flex shrink>
					<w-btn icon="reset_tv" outline @click="resetMobileAppList">
						{{ $t('mobileapp.list.reset') }}
					</w-btn>
				</w-flex>
			</v-toolbar>
		</w-flex>
		<w-flex fill-height my-2 mx-5 scroll-y>
			<w-layout fill-height justify-center child-flex>
				<w-flex px-2 fill-height>
					<v-data-table
						class="elevation-1"
						:headers="headers"
						:items="applications_filtered"
						:pagination.sync="pagination"
						:no-data-text="$t('mobileapp.nodata')"
						:no-results-text="$tc('mobileapp.noresult', { search: search })"
					>
						<template v-slot:items="props">
							<td class="text-xs-center">
								<v-tooltip bottom>
									<template v-slot:activator="{ on }">
										<v-btn icon ripple @click="editMobileApp(props.item)" v-on="on">
											<v-icon>edit</v-icon>
										</v-btn>
									</template>
									<span v-t="'mobileapp.actions.edit'" />
								</v-tooltip>
							</td>
							<td class="pointer" @click="editMobileApp(props.item)">
								<w-layout shrink>
									<w-flex shrink>
										<MobileAppIcon v-model="props.item" readonly :size="48" />
									</w-flex>
								</w-layout>
							</td>
							<td class="pointer" @click="editMobileApp(props.item)" v-html="props.item.formatted_name"></td>
							<td class="pointer text-xs-center" @click="editMobileApp(props.item)">
								<MobileAppDomains v-model="props.item" :can-select="false" />
							</td>
							<td class="pointer text-xs-center" @click="editMobileApp(props.item)">
								<MobileAppVersion v-model="props.item" />
							</td>
							<td class="pointer">
								<w-layout row justify-center align-center>
									<v-tooltip right>
										<template v-slot:activator="{ on }">
											<v-btn :disabled="props.item.upgrade_asked || props.item.last_version == 'creation'" icon tile color="primary" @click="upgrade(props.item)" v-on="on">
												<v-icon>upgrade</v-icon>
											</v-btn>
										</template>
										<span>{{ $t('mobileapp.actions.upgrade') }}</span>
									</v-tooltip>
								</w-layout>
							</td>
							<td class="pointer">
								<MobileAppAppStoreLink v-model="props.item" icon />
							</td>
							<td class="pointer">
								<MobileAppPlayStoreLink v-model="props.item" icon />
							</td>
						</template>
					</v-data-table>
				</w-flex>
			</w-layout>
		</w-flex>
	</w-layout>
</template>

<script>
import SuperAdminModuleGuard from '@/mixins/ModulesGuards/SuperAdmin/SuperAdminModuleGuard'

export default {
	name: 'AutomatedMobileAppList',
	components: {
		MobileAppAppStoreLink: () => ({
			component: import('@/components/SuperAdmin/MobileApp/Details/MobileAppAppStoreLink')
		}),
		MobileAppDomains: () => ({
			component: import('@/components/SuperAdmin/MobileApp/Details/MobileAppDomains')
		}),
		MobileAppIcon: () => ({
			component: import('@/components/SuperAdmin/MobileApp/Details/MobileAppIcon')
		}),
		MobileAppPlayStoreLink: () => ({
			component: import('@/components/SuperAdmin/MobileApp/Details/MobileAppPlayStoreLink')
		}),
		MobileAppVersion: () => ({
			component: import('@/components/SuperAdmin/MobileApp/Details/MobileAppVersion')
		})
	},
	mixins: [SuperAdminModuleGuard],
	props: {
		value: {
			required: true,
			type: Array
		}
	},
	data: function () {
		return {
			search: '',
			headers: [
				{ text: '', value: 'id', sortable: false, width: '10%' },
				{ text: this.$t('mobileapp.fields.logo'), value: 'logo_file_path', align: 'center', sortable: false, width: '10%' },
				{ text: this.$t('mobileapp.fields.title'), value: 'formatted_name', width: '30%' },
				{ text: this.$t('mobileapp.fields.domains'), value: 'domains', align: 'center', width: '20%' },
				{ text: this.$t('mobileapp.fields.version'), value: 'last_version', align: 'center', width: '10%' },
				{ text: this.$t('mobileapp.actions.upgrade'), value: 'action', sortable: false, align: 'center', width: '5%' },
				{ text: this.$t('mobileapp.fields.app_store_url'), value: 'app_store_url', align: 'center', width: '5%' },
				{ text: this.$t('mobileapp.fields.google_play_store_url'), value: 'google_play_store_url', align: 'center', width: '5%' }
			],
			pagination: {
				rowsPerPage: 10
			}
		}
	},
	computed: {
		applications: {
			get: function () {
				return this.value
			},
			set: function (val) {
				this.$emit('input', val)
			}
		},
		applications_filtered: function () {
			const search = this.search?.trim().toLowerCase()
			const searchedTextRegEx = new RegExp(search?.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&'), 'gi')
			return this.applications
				.filter(
					application =>
						search ? (application.title.toLowerCase().includes(search) ||
						application.accounting_firms.map(accounting_firm => accounting_firm.domains[0].url.toLowerCase()).some(domain => domain.includes(search)) ||
						(application.last_version && application.last_version.toLowerCase().includes(search))) : application

				)
				.map(application => {
					application.formatted_name = search && search.length ? application.title.replace(searchedTextRegEx, '<b>$&</b>') : application.title
					application.domains = application.accounting_firms
						.map(accounting_firm =>
							search && search.length ? accounting_firm.domains[0].url.replace(searchedTextRegEx, '<b>$&</b>') : accounting_firm.domains[0].url
						)
						.sort()
						.join(', ')
					return application
				})
				.sort((a, b) => {
					const textA = a.title.toUpperCase()
					const textB = b.title.toUpperCase()
					return textA < textB ? -1 : textA > textB ? 1 : 0
				})
		},
		nbUpgradePending: function () {
			const result = this.applications.filter(application => application?.upgrade_asked)
			return result && Array.isArray(result) ? result.length : 0
		}
	},
	methods: {
		createMobileApp: function () {
			this.eventBus.emit(this.events.CREATE_NEW_MOBILE_APP)
		},
		editMobileApp: function (mobileApp) {
			if (mobileApp?.id) {
				this.eventBus.emit(this.events.SELECTED_MOBILE_APP, mobileApp.id)
			}
		},
		resetMobileAppList: function () {
			this.eventBus.emit(this.events.RESET_MOBILE_APP_LIST)
		},
		upgrade: function (mobileApp) {
			this.eventBus.emit(this.events.UPGRADE_MOBILE_APP, mobileApp.id)
		},
		upgradeMobileApps: function () {
			this.eventBus.emit(this.events.UPGRADE_MOBILE_APPS)
		}
	}
}
</script>
